// Generated by Framer (c75d380)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, useActiveVariantCallback, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["awVlfmLE4", "q4uxvZM_I"];

const serializationHash = "framer-FO7Q4"

const variantClassNames = {awVlfmLE4: "framer-v-1fsvu70", q4uxvZM_I: "framer-v-1kx5z46"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const transitions = {default: {damping: 30, delay: 0, mass: 1, stiffness: 400, type: "spring"}};

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const humanReadableVariantMap = {Burger: "awVlfmLE4", X: "q4uxvZM_I"}

const getProps = ({color, height, id, tap, width, ...props}) => { return {...props, iWF8EWIN1: color ?? props.iWF8EWIN1 ?? "rgb(136, 136, 136)", TBgHLr7MW: tap ?? props.TBgHLr7MW, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "awVlfmLE4"} }

const createLayoutDependency = (props, variants) => variants.join('-') + props.layoutDependency

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;tap?: any;color?: string; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, TBgHLr7MW, iWF8EWIN1, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "awVlfmLE4", transitions, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap186ix5x = activeVariantCallback(async (...args) => {
setGestureState({isPressed: false})
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
setVariant("awVlfmLE4")
})

const onTapeiwtsm = activeVariantCallback(async (...args) => {
if (TBgHLr7MW) {
const res = await TBgHLr7MW(...args);
if (res === false) return false;
}
})

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Transition value={transition}><motion.div {...restProps} animate={variants} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1fsvu70", className, classNames)} data-framer-name={"Burger"} initial={variant} layoutDependency={layoutDependency} layoutId={"awVlfmLE4"} onHoverEnd={() => setGestureState({isHovered: false})} onHoverStart={() => setGestureState({isHovered: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} onTapStart={() => setGestureState({isPressed: true})} ref={ref ?? ref1} style={{...style}} {...addPropertyOverrides({q4uxvZM_I: {"data-framer-name": "X", "data-highlight": true, onTap: onTap186ix5x}}, baseVariant, gestureVariant)}><motion.div className={"framer-5gyxm8"} layoutDependency={layoutDependency} layoutId={"UKS3_VBXh"} {...addPropertyOverrides({q4uxvZM_I: {"data-highlight": true, onTap: onTapeiwtsm}}, baseVariant, gestureVariant)}><motion.div className={"framer-809dit"} data-framer-name={"Bottom"} layoutDependency={layoutDependency} layoutId={"FjdXmGWhB"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} variants={{q4uxvZM_I: {rotate: -45}}}/><motion.div className={"framer-1h0a7dq"} data-framer-name={"Mid"} layoutDependency={layoutDependency} layoutId={"eLPa0wAZE"} style={{backgroundColor: iWF8EWIN1, opacity: 1}} variants={{q4uxvZM_I: {opacity: 0}}}/><motion.div className={"framer-7ctl1p"} data-framer-name={"Top"} layoutDependency={layoutDependency} layoutId={"eI8g2F2IG"} style={{backgroundColor: iWF8EWIN1, rotate: 0}} variants={{q4uxvZM_I: {rotate: 45}}}/></motion.div></motion.div></Transition>
</LayoutGroup>)

});

const css = [".framer-FO7Q4[data-border=\"true\"]::after, .framer-FO7Q4 [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-FO7Q4.framer-1nrp1ow, .framer-FO7Q4 .framer-1nrp1ow { display: block; }", ".framer-FO7Q4.framer-1fsvu70 { height: 32px; overflow: hidden; position: relative; width: 32px; }", ".framer-FO7Q4 .framer-5gyxm8 { flex: none; height: 18px; left: calc(50.00000000000002% - 24px / 2); overflow: visible; position: absolute; top: calc(50.00000000000002% - 18px / 2); width: 24px; }", ".framer-FO7Q4 .framer-809dit { bottom: 0px; flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; }", ".framer-FO7Q4 .framer-1h0a7dq { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: calc(50.00000000000002% - 2px / 2); }", ".framer-FO7Q4 .framer-7ctl1p { flex: none; height: 2px; left: 0px; overflow: hidden; position: absolute; right: 0px; top: 0px; }", ".framer-FO7Q4.framer-v-1kx5z46.framer-1fsvu70, .framer-FO7Q4.framer-v-1kx5z46 .framer-5gyxm8 { cursor: pointer; }", ".framer-FO7Q4.framer-v-1kx5z46 .framer-809dit { bottom: unset; top: calc(50.00000000000002% - 2px / 2); }", ".framer-FO7Q4.framer-v-1kx5z46 .framer-1h0a7dq { left: unset; width: 2px; }", ".framer-FO7Q4.framer-v-1kx5z46 .framer-7ctl1p { left: unset; right: -1px; top: calc(50.00000000000002% - 2px / 2); width: 26px; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 32
 * @framerIntrinsicWidth 32
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"q4uxvZM_I":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"TBgHLr7MW":"tap","iWF8EWIN1":"color"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 */
const FramerWVjR5LHl3: React.ComponentType<Props> = withCSS(Component, css, "framer-FO7Q4") as typeof Component;
export default FramerWVjR5LHl3;

FramerWVjR5LHl3.displayName = "Elements/Menu Icon";

FramerWVjR5LHl3.defaultProps = {height: 32, width: 32};

addPropertyControls(FramerWVjR5LHl3, {variant: {options: ["awVlfmLE4", "q4uxvZM_I"], optionTitles: ["Burger", "X"], title: "Variant", type: ControlType.Enum}, TBgHLr7MW: {title: "Tap", type: ControlType.EventHandler}, iWF8EWIN1: {defaultValue: "rgb(136, 136, 136)", title: "Color", type: ControlType.Color}} as any)

addFonts(FramerWVjR5LHl3, [])